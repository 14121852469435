<template>
  <el-container class="project-group-main public-page-style" style="padding: 10px 25px">
    <el-header style="height: 20px">
      <el-form :inline="true" label-position="left">
        <el-form-item label="账号列表：">
          <el-cascader size="mini" :options="options" :props="props" v-model="pData.createId" filterable clearable
            @change="userChange">
          </el-cascader>
          <!-- <el-select
            v-model="pData.createId"
            filterable
            size="mini"
            placeholder="请选择"
            @change="userChange"
          >
            <el-option
              v-for="item in userArr"
              :key="item.userId"
              :label="item.fullName"
              :value="item.userId"
            >
            </el-option>
          </el-select> -->
        </el-form-item>
        <el-form-item label="搜索：">
          <el-input v-model="searchData.projectName" size="mini" placeholder="请输入SN或设备名称"></el-input>
        </el-form-item>
        <el-button class="project-group-select-btn" type="primary" plain size="mini" @click.stop="getDetails">查
          询</el-button>
        <el-button class="project-group-add-btn" type="primary" plain size="mini"
          v-show="$store.state.permissionArr.indexOf('projectGroup:add') > -1"
          @click="addProjectGroup()">新增项目组</el-button>
      </el-form>
    </el-header>
    <el-container class="project-group-main" style="
        height: calc(100% - 60px) !important;
        padding: 0;
        border: 1px solid #dadada;
        border-top: 5px solid #ff8f01;
      ">
      <el-aside class="project-group-body" style="width: 20%; overflow-y: hidden">
        <div class="pg-name-background">
          {{ projectGroupDetail.projectGroupName }}
        </div>

        <el-tabs v-model="activeName" class="iot-tab" @tab-click="tabClick">
          <el-tab-pane label="项目组列表" name="1" class="public-scroll-transparent">
            <el-form :inline="true" class="iot-search-item" style="width: 100%">
              <el-form-item>
                <el-input class="iot-search-input" placeholder="请输入项目组名称" v-model="pData.projectGroupName" size="middle"
                  style="width: 75%"></el-input>
                <el-button style="margin-left: 10px" size="mini" plain type="primary"
                  @click="checkGroup()">搜索</el-button>
              </el-form-item>
            </el-form>
            <el-card class="iot-card" style="cursor: pointer; margin: 5px" v-for="item in projectGroupArr"
              :key="item.projectGroupId" shadow="hover" @click.native="changeGroup(item)">
              <el-radio size="mini" v-model="selectGroup" :label="item.projectGroupId">{{ item.projectGroupName
                }}</el-radio>

              <!-- <i
                v-if="selectGroup == item.projectGroupId"
                style="
                  float: right;
                  color: #67c23a;
                  margin-top: 5px;
                  font-weight: 900;
                "
                class="el-icon-check"
              ></i> -->
            </el-card>
            <el-pagination :page-size="pData.size" style="float: right" :total="pTotal"
              layout="prev, pager, next, jumper" class="page" :pager-count="5" small @current-change="
                (p) => {
                  $set(pData, 'current', p);
                  getGroupByUser();
                }
              " @prev-click="
                () => {
                  $set(pData, 'current', pData.current - 1);
                  getGroupByUser();
                }
              " @next-click="
                () => {
                  $set(pData, 'current', pData.current + 1);
                  getGroupByUser();
                }
              ">
            </el-pagination>
          </el-tab-pane>
          <el-tab-pane label="项目组详情" name="2" :disabled="!userId">
            <div class="project-group-btn">
              <el-button type="success" plain size="mini" v-show="
                  $store.state.permissionArr.indexOf('projectGroup:update') > -1
                " @click="editProjectGroup(projectGroupDetail)">修改</el-button>
              <el-button type="danger" plain size="mini" v-show="
                  $store.state.permissionArr.indexOf('projectGroup:delete') > -1
                " @click="delProjectGroup(projectGroupDetail.projectGroupId)">删除</el-button>
              <el-button :disabled="!projectGroupChangeArr" type="primary" plain size="mini" v-show="
                  $store.state.permissionArr.indexOf('projectGroup:add') > -1
                " @click="distributeProject()">分配项目</el-button>
              <el-button type="primary" plain size="mini" v-show="
                  $store.state.permissionArr.indexOf('projectGroup:add') > -1
                " @click="distributeUser()">分配用户</el-button>
            </div>
            <el-row>
              <el-col :span="12">项目总数： {{ projectGroupDetail.projectCount }}个</el-col>
              <el-col :span="12">未分组项目： {{ projectGroupDetail.notProjectCount }}个</el-col>
            </el-row>
            <el-row>
              联系人：
              <div style="
                  margin: 5px;
                  display: inline-block;
                  background-color: #b4b4b5;
                  border-radius: 10px;
                  color: #ffffff;
                  line-height: 20px;
                  padding: 0px 10px;
                " v-for="(item, index) in projectGroupDetail.contacts" :key="index">
                {{ item.contactsName }}
              </div>
              <!-- 联系人：
              <el-tag
                style="margin: 5px"
                v-for="(item, index) in projectGroupDetail.contacts"
                :key="index"
                >{{ item.contactsName }}</el-tag
              > -->
            </el-row>

            <!-- <el-row>在线项目：{{ projectGroupDetail.onlineCount }}个 </el-row>
            <el-row>离线项目：{{ projectGroupDetail.offlineCount }}个 </el-row>
            <el-row>报警项目：{{ projectGroupDetail.alarmCount }}个 </el-row> -->
            <el-row>备注： {{ projectGroupDetail.remark }}</el-row>
            <el-row>地址： {{ projectGroupDetail.projectGroupAddr }}</el-row>
            <div class="iot-chart-div">
              <div class="iot-pie" id="iotPie" ref="pie"></div>
              <div class="iot-legand-div">
                <div class="iot-legand">
                  <div style="background-color: #72dd74"></div>
                  <span>正常{{ projectGroupDetail.normalCount }}台</span>
                </div>
                <div class="iot-legand">
                  <div style="background-color: #d8d8d8"></div>
                  <span>离线{{ projectGroupDetail.offlineCount }}台</span>
                </div>
                <div class="iot-legand">
                  <div style="background-color: #f56c6c"></div>
                  <span>报警{{ projectGroupDetail.alarmCount }}台</span>
                </div>
                <div class="iot-legand">
                  <div style="background-color: #e6a23c"></div>
                  <span>故障{{ projectGroupDetail.faultCount }}台</span>
                </div>
              </div>
            </div>
            <el-row class="project-group-map">
              <div style="width: 100%; height: 200px">
                <el-amap vid="amap1" :amap-manager="amapManager" :zoom="zoom1" :center="center1">
                  <el-amap-marker v-for="(marker, index) in markers1" :key="index" :position="marker.position"
                    :events="marker.events" :plugin="plugin"></el-amap-marker>
                </el-amap>
              </div>
            </el-row>
          </el-tab-pane>
        </el-tabs>
      </el-aside>
      <el-container>
        <el-main class="project-body">
          <el-header>
            <el-form :inline="true">
              <el-form-item label="设备名称">
                <el-input v-model="pjData.projectName" clearable size="mini" placeholder="请输入设备名称">
                </el-input>
              </el-form-item>
              <el-button class="project-group-select-btn" type="primary" plain size="mini" @click.stop="selectProject">查
                询</el-button>
              <el-button class="project-group-binding-btn" type="primary" plain size="mini"
                v-show="$store.state.permissionArr.indexOf('project:add') > -1&&$store.state.userId==pData.createId"
                @click="addProject()">绑定设备</el-button>
            </el-form>
          </el-header>
          <!--项目展示 -->
          <div class="project-body-item">
            <div class="project-item" v-for="item in projectPage" :key="item.projectId"
              @click="openProject(item.projectId, item.planId, item)">
              <!-- <div class="project-item"> -->
              <div class="project-item-image">
                <img :src="
                    constant.OSS_URL + constant.PROJECT_IMG + item.projectImg
                  " />
              </div>
              <div class="project-item-words">
                <el-tooltip v-if="item.projectName.length>20" class="item" effect="light" :content="item.projectName"
                  placement="top" transition="el-zoom-in-bottom">
                  <h4>{{ item.projectName}}</h4>
                </el-tooltip>
                <h4 v-else>{{ item.projectName}}</h4>
                <!-- <div class="remark" :title="item.remark">
                  {{ item.remark }}
                </div> -->
                <div>
                  <el-tag size="small" v-if="item.projectState == 1 && item.serverType == 0" type="success">正常</el-tag>
                  <el-tag size="small" v-if="item.projectState == 2 && item.serverType == 0" type="info">离线</el-tag>
                  <el-tag type="danger" size="small" v-if="item.projectState == 3 && item.serverType == 0">报警</el-tag>
                  <el-tag type="success" size="small" v-if="item.serverType == 1">三方</el-tag>
                  <el-tag size="small" v-if="item.projectState == 4 && item.serverType == 0" type="warning">故障</el-tag>
                  <el-tag style="margin-left: 20px" size="small" @click.stop="editProject(item.projectId)" v-show="
                      $store.state.permissionArr.indexOf('project:update') > -1
                    ">修改</el-tag>

                  <el-tag style="margin-left: 10px" size="small" type="info" @click.stop="delProject(item.projectId)"
                    v-show="
                      $store.state.permissionArr.indexOf('project:delete') > -1
                    ">解绑</el-tag>
                </div>
                <!-- <div class="project-item-links">
                  <div>
                    <el-button
                      type="primary"
                      plain
                      size="mini"
                      :underline="false"
                      v-show="
                        $store.state.permissionArr.indexOf('project:update') >
                        -1
                      "
                      @click.stop="editProject(item.projectId)"
                      >修 改</el-button
                    >
                    &nbsp;
                    <el-button
                      type="danger"
                      plain
                      size="mini"
                      :underline="false"
                      v-show="
                        $store.state.permissionArr.indexOf('project:delete') >
                        -1
                      "
                      @click.stop="delProject(item.projectId)"
                      >删 除</el-button
                    >
                  </div>
                </div> -->
              </div>
              <!-- </div> -->
            </div>
          </div>
          <el-pagination @size-change="projectSizeChange" @current-change="projectCurrentChange"
            :current-page="pjData.current" :page-size="pjData.size" layout="total, prev, pager, next, jumper"
            :total="pjData.total" class="pagination"></el-pagination>
        </el-main>
      </el-container>
      <!-- 新增项目组表单 -->
      <el-dialog top="3%" :title="formTitle" :show-close="false" :visible.sync="dialogVisibile"
        :destroy-on-close="false" :close-on-click-modal="false" class="iot-dialog" width="50%"
        @close="beforeClose">
        <el-form ref="projectGroupRef" :model="projectGroupEditObj" label-width="120px" label-position="left"
          :rules="rules">
          <el-form-item label="项目组名称" prop="projectGroupName">
            <el-input v-model="projectGroupEditObj.projectGroupName"
              :disabled="projectGroupEditObj.isDefault==1"></el-input>
          </el-form-item>
          <el-form-item label="项目组说明" prop="remark">
            <el-input type="textarea" maxlength="100" show-word-limit placeholder="请输入项目组说明"
              v-model="projectGroupEditObj.remark">
            </el-input>
          </el-form-item>
          <el-form-item label="联系人">
            <el-select v-model="projectGroupEditObj.contactsIds" 
            multiple 
            collapse-tags 
            placeholder="请选择联系人" 
            filterable>
              <el-option v-for="item in contactArr" :key="item.contactsId" :label="item.contactsName"
                :value="item.contactsId">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="地址" v-if="dialogVisibile">
            <el-amap-search-box ref="searchRef" class="search-box" :search-option="searchOption"
              :default="mapSearchDefault" :on-search-result="onSearchResult"></el-amap-search-box>
            <div style="width: 100%; height: 150px; margin-top: 10px">
              <el-amap vid="amap" :amap-manager="amapManager" :zoom="zoom1" :center="center">
                <el-amap-marker v-for="(marker, index) in markers" :key="index" :position="marker.position"
                  :events="marker.events" :plugin="plugin"></el-amap-marker>
              </el-amap>
            </div>
          </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer">
          <el-button type="primary" plain size="mini" @click="groupSubmitForm(projectGroupEditObj.isDefault)">确
            定</el-button>
          <el-button type="info" plain size="mini"
            @click="dialogVisibile = false,$refs.projectGroupRef.resetFields();beforeClose()">取 消</el-button>
        </div>
      </el-dialog>
      <!-- 新增项目表单 -->
      <!-- 绑定项目 -->
      <el-dialog top="5%" :title="formTitle" :show-close="false" :visible.sync="bindingDialog" :destroy-on-close="false"
        :close-on-click-modal="false" class="add-project-dialog" width="50%">
        <el-form v-if="isCreate" class="project-form" ref="projectForm" :model="pjForm" label-width="200px"
          label-position="left" :rules="rules">
          <el-form-item label="设备名称" prop="projectName">
            <el-input size="mini" v-model="pjForm.projectName" placeholder="请输入设备名称"></el-input>
          </el-form-item>
          <el-form-item label="方案" prop="planId">
            <el-select v-model="pjForm.planId" filterable size="mini" placeholder="请选择" @change="getGatewayList">
              <el-option v-for="item in planList" :key="item.planId" :label="item.planName" :value="item.planId">
              </el-option>
            </el-select>
          </el-form-item>
          <div v-if="select.serverType == 0">
            <div v-for="item in gatewayList" :key="item.gatewayId">
              <el-form-item>
                <template slot="label">
                  <div style="
                      width: 200px;
                      overflow: hidden;
                      white-space: nowrap;
                      text-overflow: ellipsis;
                    ">
                    网关：{{ item.gatewayName }}
                  </div>
                </template>
                <el-input size="mini" v-model="vos[item.gatewayId]" placeholder="请输入网关SN"></el-input>
              </el-form-item>
            </div>
          </div>
          <div v-if="select.serverType == 1">
            <div v-for="item in gatewayList" :key="item.gatewayId">
              <el-form-item>
                <template slot="label">
                  <div style="
                      width: 200px;
                      overflow: hidden;
                      white-space: nowrap;
                      text-overflow: ellipsis;
                    ">
                    {{ item.gatewayName }}
                  </div>
                </template>
                <el-input size="mini" v-model="vos[item.gatewayId]" placeholder="请输入网关SN"></el-input>
              </el-form-item>
            </div>
            <el-form-item prop="productId">
              <template slot="label">
                <i class="iconfont" style="color: rgb(82, 159, 211)">&#xe607;</i>
                <span>产品ID</span>
              </template>
              <el-input size="mini" v-model="pjForm.productId" placeholder="请输入产品ID"></el-input>
            </el-form-item>
            <el-form-item prop="deviceId">
              <template slot="label">
                <i class="iconfont" style="color: rgb(82, 159, 211)">&#xe607;</i>
                <span>设备ID</span>
              </template>
              <el-input size="mini" v-model="pjForm.deviceId" placeholder="请输入设备ID"></el-input>
            </el-form-item>
            <el-form-item prop="thirdSn">
              <template slot="label">
                <i class="iconfont" style="color: rgb(82, 159, 211)">&#xe607;</i>
                <span>设备密钥</span>
              </template>
              <el-input size="mini" v-model="pjForm.thirdSn" placeholder="请输入设备密钥"></el-input>
            </el-form-item>
            <el-form-item v-if="select.serverId != 1" label="发布主题" prop="topicPub">
              <el-input size="mini" v-model="pjForm.topicPub" autocomplete="off"></el-input>
            </el-form-item>
            <el-form-item v-if="select.serverId != 1" label="发布质量" prop="pubQos">
              <el-radio-group v-model="pjForm.pubQos">
                <el-radio v-for="(item, index) in radio" :key="index" :label="item.value">{{ item.names }}</el-radio>
                <!-- <el-radio :label="1">备选项</el-radio>
            <el-radio :label="2">备选项</el-radio> -->
              </el-radio-group>
            </el-form-item>

            <el-form-item v-if="select.serverId != 1" label="订阅主题" prop="topicSub">
              <el-input size="mini" v-model="pjForm.topicSub" autocomplete="off"></el-input>
            </el-form-item>
            <el-form-item v-if="select.serverId != 1" label="订阅质量">
              <el-radio-group v-model="pjForm.subQos">
                <el-radio v-for="(item, index) in radio" :key="index" :label="item.value">{{ item.names }}</el-radio>
                <!-- <el-radio :label="1">备选项</el-radio>
            <el-radio :label="2">备选项</el-radio> -->
              </el-radio-group>
            </el-form-item>
          </div>

          <el-form-item label="备注" prop="remark">
            <el-input type="textarea" size="mini" :autosize="{ minRows: 2, maxRows: 4 }" style="width: 70%"
              maxlength="125" show-word-limit v-model="pjForm.remark" placeholder="请输入"></el-input>
          </el-form-item>
        </el-form>
        <el-form v-else-if="!isCreate" class="project-form" ref="projectForm1" :model="eidtForm" label-width="120px"
          label-position="left" :rules="rules">
          <el-form-item label="设备名称" prop="projectName">
            <el-input size="mini" v-model="eidtForm.projectName" placeholder="请输入设备名称"></el-input>
          </el-form-item>
          <el-form-item v-if="eidtForm.topicPub" label="发布主题" prop="topicPub">
            <el-input size="mini" v-model="eidtForm.topicPub" placeholder="请输入发布主题"></el-input>
          </el-form-item>
          <el-form-item v-if="eidtForm.topicPub" label="发布质量">
            <el-input size="mini" v-model="eidtForm.topicSub" placeholder="请输入发布质量"></el-input>
          </el-form-item>
          <el-form-item label="备注" prop="remark">
            <el-input type="textarea" size="mini" :autosize="{ minRows: 2, maxRows: 4 }" style="width: 70%"
              maxlength="125" show-word-limit v-model="eidtForm.remark" placeholder="请输入"></el-input>
          </el-form-item>
        </el-form>

        <div slot="footer" class="dialog-footer">
          <el-button type="primary" plain size="mini" @click="submitProjectForm()">确 定</el-button>
          <el-button type="info" plain size="mini" @click="bindingDialog = false,$refs.projectForm.resetFields();">取
            消</el-button>
        </div>
      </el-dialog>

      <!-- 分配项目表单 -->
      <el-dialog top="3%" :title="formTitle" :show-close="false" :visible.sync="projectDialog" :destroy-on-close="false"
        :close-on-click-modal="false" :before-close="closeBinding" class="iot-dialog" width="50%">
        <el-form ref="bindRef" :model="changeForm" label-width="120px">
          <el-form-item label="目标项目组">
            <el-select v-model="changeForm.projectGroupId" placeholder="请选择">
              <el-option v-show="item.projectGroupId != selectGroup" v-for="item in projectGroupChangeArr"
                :key="item.projectGroupId" :label="item.projectGroupName" :value="item.projectGroupId">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="所选项目">
            <el-checkbox-group v-model="changeForm.projectIds">
              <el-checkbox v-for="item in projectArr" :label="item.projectId" :key="item.projectId">{{ item.projectName
                }}</el-checkbox>
            </el-checkbox-group>
          </el-form-item>
        </el-form>
        <div slot="footer">
          <el-button type="primary" plain size="mini" @click="submitDis(changeForm)">确 定</el-button>
          <el-button type="info" plain size="mini" @click="projectDialog = false">取 消</el-button>
        </div>
      </el-dialog>
      <!-- 三方项目信息 -->
      <el-dialog top="3%" :show-close="false" :visible.sync="details" :destroy-on-close="false"
        :close-on-click-modal="true" :before-close="closeBind" class="iot-dialog" width="50%">
        <!-- <template slot="label">
          <i class="iconfont" style="color: rgb(82, 159, 211)">&#xe607;</i>
          <span>{{ item.gatewayName }}</span>
        </template>
        <el-input
          size="mini"
          v-model="projectDetails.vos[item.gatewayId]"
          placeholder="请输入网关SN"
        ></el-input>-->
        <el-form ref="form" :model="projectDetails" label-width="150px" :rules="rules">
          <el-form-item>
            <template slot="label">
              <i class="iconfont" style="color: rgb(82, 159, 211)">&#xe607;</i>
              <span>所属方案</span>
            </template>
            <el-input size="mini" v-model="projectDetails.planName" placeholder="所属方案" readonly></el-input>
          </el-form-item>
          <div v-for="(item, index) in projectDetails.dtus" :key="index">
            <el-form-item>
              <template slot="label">
                <i class="iconfont" style="color: rgb(82, 159, 211)">&#xe607;</i><span> 所属网关 </span>
              </template>
              <el-input size="mini" v-model="item.gatewayName" placeholder="网关SN" readonly></el-input>

              <span> 序列号 </span>
              <el-input size="mini" v-model="item.dtuSn" placeholder="网关SN" readonly></el-input>
            </el-form-item>
          </div>
          <el-form-item >
            <template slot="label">
              <i class="iconfont" style="color: rgb(82, 159, 211)">&#xe607;</i>
              <span>产品ID</span>
            </template>
            <el-input size="mini" v-model="projectDetails.productId" placeholder="产品ID" readonly></el-input>
          </el-form-item>
          <el-form-item>
            <template slot="label">
              <i class="iconfont" style="color: rgb(82, 159, 211)">&#xe607;</i>
              <span>设备ID</span>
            </template>
            <el-input size="mini" v-model="projectDetails.deviceId" placeholder="设备ID" readonly></el-input>
          </el-form-item>
          <el-form-item>
            <template slot="label">
              <i class="iconfont" style="color: rgb(82, 159, 211)">&#xe607;</i>
              <span>设备密钥</span>
            </template>
            <el-input size="mini" v-model="projectDetails.thirdSn" placeholder="设备密钥" readonly></el-input>
          </el-form-item>
          <el-form-item label="发布主题">
            <el-input readonly size="mini" v-model="projectDetails.topicPub" autocomplete="off"></el-input>
          </el-form-item>
          <el-form-item label="发布质量">
            <el-radio-group readonly v-model="projectDetails.pubQos">
              <el-radio readonly v-for="(item, index) in radio" :key="index" :label="item.value">{{ item.names
                }}</el-radio>
              <!-- <el-radio :label="1">备选项</el-radio>
            <el-radio :label="2">备选项</el-radio> -->
            </el-radio-group>
          </el-form-item>

          <el-form-item label="订阅主题">
            <el-input readonly size="mini" v-model="projectDetails.topicSub" autocomplete="off"></el-input>
          </el-form-item>
          <el-form-item label="订阅质量">
            <el-radio-group readonly v-model="projectDetails.subQos">
              <el-radio readonly v-for="(item, index) in radio" :key="index" :label="item.value">{{ item.names
                }}</el-radio>
              <!-- <el-radio :label="1">备选项</el-radio>
            <el-radio :label="2">备选项</el-radio> -->
            </el-radio-group>
          </el-form-item>
        </el-form>
        <div class="servers">
          <span>第三方设备无法在此平台查看状态，默认为离线状态</span>
        </div>
        <div slot="footer" class="dialog-footer">
          <!-- <el-button type="primary" plain size="mini" @click="submitProjectForm"
            >修改</el-button
          > -->
          <el-button type="info" plain size="mini" @click="details = false">取消</el-button>
        </div>
      </el-dialog>
      <!-- 分配用户表单 -->
      <el-dialog top="3%" :title="formTitle" :show-close="false" :visible.sync="userVisibile" :destroy-on-close="false"
        :close-on-click-modal="false" class="iot-dialog" width="50%">
        <el-form ref="userForm" :model="changeUser" label-width="120px" label-position="left">
          <el-form-item label="目标用户">
            <el-cascader size="mini" :options="options" :props="props1" v-model="changeUser.userId" filterable
              clearable>
            </el-cascader>
            <!-- <el-select v-model="changeUser.userId" filterable placeholder="请选择">
              <el-option
                v-for="item in projectGroupChangeArr1"
                :key="item.userId"
                :label="item.fullName"
                :value="item.userId"
              >
              </el-option>
            </el-select> -->
          </el-form-item>
          <el-form-item label="所选项目">
            <el-checkbox-group v-model="changeUser.projectIds" class="checkboxgroup">
              <el-checkbox v-for="item in projectArr" :label="item.projectId" :key="item.projectId">{{ item.projectName
                }}</el-checkbox>
            </el-checkbox-group>
          </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer">
          <el-button type="primary" plain size="mini" @click="submitUser(changeUser)">确 定</el-button>
          <el-button type="info" plain size="mini" @click="userVisibile = false">取 消</el-button>
        </div>
      </el-dialog>
      <el-dialog v-if="pShow" class="detailDialog" :visible.sync="pShow" :width="getWidth()" :show-close="false"
        :lock-scroll="true" top="3%" :destroy-on-close="true" @closed="closed" :before-close="closeDialog">
        <template slot="title">
          <p>
            <img v-if="vStatus == 1" title="加速上传中..." style="float: left; width: 25px; height: 25px"
              src="../assets/v.gif" />
            <img v-if="vStatus == 0" title="停止加速" style="float: left; width: 25px; height: 25px" src="../assets/v1.png"
              @click="openV(projectDetail.projectId)" />
            <span style="float: left; margin-top: -4px">{{
              currentPName
              }}</span>
            <!-- <span
              style="cursor: pointer"
              
              >{{ vStatus == 0 ? "未加速" : "加速上传中..." }}</span
            > -->
            <!-- <div v-if="cameraList.length!=0"> -->
            <el-dropdown style="float: right" v-for="item in cameraList" :key="item.ysId">
              <img style="" :src="constant.SYSTEM_IMG + 'menu1.png'" @click="monitorClick(item)" />
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item>摄像头名称：{{ item.ysName }}
                </el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>

            <el-dropdown style="float: right" :hide-on-click="false">
              <img style="margin-right: 10px" :src="constant.SYSTEM_IMG + 'menu5.png'" />
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item>所属方案：{{ projectDetail.planName }}</el-dropdown-item>
                <el-dropdown-item>所属用户：{{ projectDetail.fullName }}</el-dropdown-item>
                <el-dropdown-item>所属项目组：{{ projectDetail.projectGroupName }}</el-dropdown-item>
                <el-dropdown-item style="position: relative;">触发条件：
                  <el-tag style="position:  absolute;
  right: 25px; top: 2px;" @click="passVisible=true" plain>编辑</el-tag>
                  <!-- 输入密码 -->
                  <el-dialog title="输入密码" width="15%" :visible.sync="passVisible" append-to-body top="15%">
                    <el-input @keyup.enter.native="check()" v-model="password"></el-input>
                    <div slot="footer" class="dialog-footer">
                      <el-button type="primary" @click="check()">确 定</el-button>
                      <el-button @click="passVisible=false">取 消</el-button>
                    </div>
                  </el-dialog>
                  <!-- 触发条件列表弹框 -->
                  <el-dialog title="触发条件" width="60%" :visible.sync="triggerVisible" :before-close="triggerClose"
                    append-to-body top="6%">
                    <div style="width: 100%; height: 465px; overflow: auto">
                      <el-dialog width="15%" :visible.sync="valueVisible" :before-close="valueConfig" append-to-body
                        top="15%">
                        <el-input v-model="triggerValue" @keyup.enter.native="proAlarm()"></el-input>
                        <div slot="footer" class="dialog-footer">
                          <el-button type="primary" @click="proAlarm()">确 定</el-button>
                          <el-button @click="valueVisible = false">取 消</el-button>
                        </div>
                      </el-dialog>
                      <el-table :data="triggerList" stripe border="">
                        <el-table-column prop="alarmName" label="触发器名称" width="140"></el-table-column>
                        <!-- <el-table-column
                          prop="dtuSn"
                          label="触发时间"
                          width="200"
                        ></el-table-column> -->
                        <el-table-column label="触发条件">
                          <template slot-scope="scope">
                            <div v-if="scope.row.conditionTagShow != 'in'">
                              <span>{{ scope.row.paramName }}</span>
                              <span>{{ scope.row.conditionTagShow }}</span>
                              <span v-if="scope.row.atype == 1" @click="setValue(scope.row, 'aValue')"
                                class="editNum">{{ scope.row.aValue }}</span>
                              <span v-else>{{ scope.row.aParamName }}</span>
                              <span v-if="scope.row.aAddSubt">{{
                                scope.row.aAddSubt
                                }}</span>
                              <span v-if="scope.row.xValue&&scope.row.atype!=1" class="editNum"
                                @click="setValue(scope.row, 'xValue')">{{ scope.row.xValue }}</span>
                              <span v-if="scope.row.sValue != 0">({{ scope.row.sParamName }})</span>
                            </div>
                            <div v-else>
                              <span v-if="scope.row.atype == 1" @click="setValue(scope.row, 'aValue')"
                                class="editNum">{{ scope.row.aValue }}</span>
                              <span v-else>{{ scope.row.aParamName }}</span>
                              <span>&lt;</span>
                              <span>{{ scope.row.paramName }}</span>
                              <span v-if="scope.row.aAddSubt">&lt;</span>
                              <span v-if="scope.row.xValue " class="editNum" @click="setValue(scope.row, 'xValue')">{{
                                scope.row.xValue }}</span>
                            </div>
                          </template>
                        </el-table-column>
                        <el-table-column label="触发方式">
                          <template slot-scope="scope">
                            <span v-if="scope.row.forward == 0">报警</span>
                            <span v-if="scope.row.forward == 1">报警联动</span>
                            <span v-if="scope.row.forward == 2">联动</span>
                          </template>
                        </el-table-column>
                        <el-table-column prop="aInterval" label="触发间隔" width="160px"></el-table-column>
                        <el-table-column label="状态" width="150" fixed="right">
                          <template slot-scope="scope">
                            <span style="cursor: pointer;" :class="{ select: scope.row.disType == 0 }"
                              @click="changeSelect(0, scope.row)">启用</span>
                            <span> / </span>
                            <span style="cursor: pointer;" :class="{ select: scope.row.disType == 1 }"
                              @click="changeSelect(1, scope.row)">禁用</span>
                          </template>
                        </el-table-column>
                      </el-table>
                    </div>
                    <div style="
                      width: 100%;
                      display: flex;
                      justify-content: flex-end;
                    ">
                      <el-pagination background @size-change="triggerSizeChange" @current-change="triggerCurrentChange"
                        :current-page="triggerPage.current" :page-sizes="[10, 20, 30, 40]" :total="triggerPage.total"
                        :page-size="triggerPage.size" style="" layout="total, sizes, prev, pager, next, jumper"
                        @prev-click="
                        () => {
                          $set(triggerPage, 'current', triggerPage.current - 1);
                          getTriggerList();
                        }
                      " @next-click="
                        () => {
                          $set(triggerPage, 'current', triggerPage.current + 1);
                          getTriggerList();
                        }
                      ">
                      </el-pagination>
                    </div>

                    <div slot="footer" class="dialog-footer">
                      <el-button type="primary" @click="upProAlarm">确 定</el-button>
                      <el-button @click="triggerVisible = false">取 消</el-button>
                    </div>
                  </el-dialog>
                </el-dropdown-item>
                <el-dropdown-item>备注：
                  <p style="max-width: 200px">
                    {{ projectDetail.remark }}
                  </p>
                </el-dropdown-item>
                <el-dropdown-item>绑定时间：{{ projectDetail.createTime }}</el-dropdown-item>
                <el-dropdown-item v-if="networkModeId.includes(projectDetail.dtus[0].networkModeId)"
                  style="display: flex;align-items: center;justify-content: space-between;">
                  <div>地址：</div>
                  <div>{{address}}</div>
                  <i class="el-icon-refresh" style="color:#409eff;margin-left: 20px;"
                    @click="getAddress(projectDetail.dtus[0].dtuSn,1)"></i>
                </el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
            <el-dropdown style="float: right" v-if="0 == 1">
              <img :src="constant.SYSTEM_IMG + 'menu1.png'" />
              <el-dropdown-menu slot="dropdown"> </el-dropdown-menu>
            </el-dropdown>
            <el-dropdown style="float: right" :hide-on-click="false">
              <img v-if="projectState != 2" :src="
                  constant.SYSTEM_IMG +
                  'menu' +
                  (signal > 20 ? '2' : signal > 15 ? '3' : '4') +
                  '.png'
                " />
              <img v-else-if="projectState == 2" :src="constant.SYSTEM_IMG + 'menu6.png'" />
              <el-dropdown-menu slot="dropdown">
                <div class="dtus">
                  <el-carousel indicator-position="outside" height="250px" :autoplay="false">
                    <el-carousel-item v-for="(item, index) in projectDetail.dtus" :key="index">
                      <div class="dtus_body">
                        <el-dropdown-item :divided="index == 0 ? false : true"
                          style="display: flex; justify-content: space-between">
                          <div>{{ item.gatewayName }}</div>
                          <div v-if="item.dtuState == 1">
                            <el-tag type="success" plain>在线</el-tag>
                          </div>
                          <div v-else-if="item.dtuState == 2">
                            <el-tag type="info" plain>离线</el-tag>
                          </div>
                          <div v-else-if="item.dtuState == 3">
                            <el-tag type="danger" plain>告警</el-tag>
                          </div>
                        </el-dropdown-item>
                        <el-dropdown-item style="display: flex; justify-content: space-between">
                          <div>信号：{{ item.signal }}</div>
                          <el-button v-if="networkModeId.includes(projectDetail.dtus[0].networkModeId)" size="mini"
                            type="success" style="height: 29px; margin: auto 0" @click="getSignal(item, projectDetail)"
                            plain>历史曲线</el-button>
                        </el-dropdown-item>
                        <el-dropdown-item>序列号：{{ item.dtuSn }}</el-dropdown-item>
                        <el-dropdown-item>卡号：{{ item.ccid }}</el-dropdown-item>
                        <el-dropdown-item style="position: relative">剩余流量：{{ index | getLeft }}
                          <div class="btn">
                            <el-button v-if="simVisible" size="mini" type="success" @click="getSIM(item, index)"
                              plain>流量查询</el-button>
                            <el-button v-else size="mini" type="success" icon="el-icon-loading" plain></el-button>
                          </div>
                        </el-dropdown-item>
                        <el-dropdown-item>SIM卡状态：{{ index | getCardStatus }}


                        </el-dropdown-item>
                        <el-dropdown-item>到期时间：{{ index | getValiddate }}</el-dropdown-item>
                      </div>
                    </el-carousel-item>
                  </el-carousel>
                </div>
              </el-dropdown-menu>
            </el-dropdown>
            <el-dropdown style="float: right" :hide-on-click="false">
              <span :style="project.style" class="iot-project-status">{{
                project.content
                }}</span>
              <el-dropdown-menu slot="dropdown" style="overflow-y: auto;max-height: 600px;">
                <el-dropdown-item style="width: 150px; display: flex; justify-content: space-between"
                  v-for="(item, index) in projectDetail.dtus" :key="index">
                  <div>{{ item.gatewayName }}</div>
                  <div v-if="item.dtuState == 1">
                    <el-tag type="success" plain size="mini">在线</el-tag>
                  </div>
                  <div v-else-if="item.dtuState == 2">
                    <el-tag type="info" plain size="mini">离线</el-tag>
                  </div>
                  <div v-else-if="item.dtuState == 3">
                    <el-tag type="danger" plain size="mini">告警</el-tag>
                  </div>
                </el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
            <span class="iot-project-status" style="color:black;font-size: 20px;margin-right: 25px;">{{currentTime
              }}</span>
          </p>
        </template>
        <!-- 组态展示 -->
        <HMIExecute :class="{ filterCss: projectState == 2 }" v-if="pShow" :key="hmiKey" :canvasWidth="canvasWidth">
        </HMIExecute>
        <!-- 组态展示 -->
        <!-- 历史曲线弹层 -->
        <el-dialog v-if="signalVisible" class="detailDialogs" :visible.sync="signalVisible" width="80%"
          :show-close="false" :lock-scroll="true" top="7%" append-to-body  style="height: 800px;">
          <div>
            <div class="dtuSignalTitle">
              <span style="padding-right: 20px">选择日期:</span>
              <el-date-picker v-model="projectFrom.date" type="date" size="mini" :picker-options="pickerOptions"
                @change="setSignalHistory" value-format="yyyy-MM-dd" placeholder="选择日期">
              </el-date-picker>
            </div>
            <div class="dtuSignal" :style="`width: 1250px; height:300px;zoom:${zoom};transform:scale(${1/zoom});transform-origin:0 0`">
              <div id="dtuSignal" ref="dtuSignal"  >
            </div>
            </div>
          </div>
          <span slot="footer" class="dialog-footer">
            <el-button size="mini" type="primary" plain  @click="signalVisible = false">关闭</el-button>
          </span>
        </el-dialog>
        <span slot="footer" class="dialog-footer">
          <el-button size="mini" type="primary" plain @click="closeDialog">关闭</el-button>
        </span>
      </el-dialog>

      <el-dialog v-if="snVisible" class="detailDialog" :visible.sync="snVisible" width="40%" :show-close="false"
        :lock-scroll="true" top="10%">
        <div class="dtuBox">
          <el-form ref="form" :model="dtuSnForm" label-width="100px">
            <el-form-item label="所属方案">
              <el-input v-model="dtuSnForm.planName" readonly></el-input>
            </el-form-item>
            <el-form-item label="所属网关">
              <el-input v-model="dtuSnForm.gatewayName" readonly></el-input>
            </el-form-item>
            <el-form-item label="所属账号">
              <el-input v-model="dtuSnForm.bindingUser" readonly></el-input>
            </el-form-item>
            <el-form-item label="所属项目组">
              <el-input v-model="dtuSnForm.projectGroupName" readonly></el-input>
            </el-form-item>
            <el-form-item label="所属项目">
              <el-input v-model="dtuSnForm.projectName" readonly></el-input>
            </el-form-item>
          </el-form>
        </div>
        <span slot="footer" class="dialog-footer">
          <el-button size="mini" type="primary" plain @click="snVisible = false">关闭</el-button>
        </span>
      </el-dialog>
    </el-container>
    <el-dialog title="设备列表" :visible.sync="deviceList" width="40%">
      <el-table :data="tableData" style="width: 100%">
        <el-table-column align="center" prop="dtuSn" label="dtusn" width="180">
        </el-table-column>
        <el-table-column align="center" prop="projectGroupName" label="所属项目组" width="180">
        </el-table-column>
        <el-table-column align="center" prop="projectName" label="设备名称">
        </el-table-column>
        <el-table-column align="center" prop="projectName" label="操作">
          <template slot-scope="scope">
            <el-button type="primary" plain size="mini" @click="searchDtails(scope.row.dtuSn)">查看</el-button>
          </template>
        </el-table-column>
      </el-table>
      <el-row type="flex" justify="end" style="margin-top:20px">
        <el-pagination small @size-change="handleSizeChange" @current-change="handleCurrentChange"
          :current-page="searchData.current" :page-sizes="[2, 5, 10]" :page-size="searchData.size"
          layout="total, sizes, prev, pager, next, jumper" :total="searchData.total">
        </el-pagination>
      </el-row>
      <span slot="footer" class="dialog-footer">
        <el-button @click="deviceList = false" size="mini" type="primary" plain>关 闭</el-button>
      </span>
    </el-dialog>
  </el-container>
  <!-- 搜索设备弹出层 -->
</template>

<script>
  let that;
  import * as echarts from "echarts";
  import { visitUrl } from "@/util/global.js";
  import HMIExecute from "./HMIExecute.vue";
  import { TABLE_HEADER_COLOR } from "../util/theme";
  import { PROJECT_IMG, OSS_URL, SYSTEM_IMG } from "../util/constant";
  // import gWebSocket from "../util/draw/HMIWebSocket.js";
  import AMap from "vue-amap";
  import reg from "../util/regular";
  import { number } from "echarts";
  // import { baseUrl } from "../util/global";
  // import { addressUrl } from "../util/global";
  let amapManager = new AMap.AMapManager();

  // let Geocoder;
  export default {
    name: "ProjectGroup",
    components: { HMIExecute },
    data() {
      return {

        timers: null,
        options: [],
        props1: {
          lazy: true,
          checkStrictly: true,
          emitPath: false,
          lazyLoad(node, resolve) {
            return that.getlazyLoad(node, resolve)
          }
        },
        props: {
          lazy: true,
          checkStrictly: true,
          emitPath: false,
          lazyLoad(node, resolve) {
            that.userChange(node.value)
            return that.getlazyLoad(node, resolve)
          }
        },
        searchData: {
          projectName: '',
          size: 5,
          current: 1,
          projectState: 0,
          total: 0
        },
        tableData: [],
        deviceList: false,
        loading: false,
        zoom: 1,
        userId: '',
        networkModeId: [1, 7, 8],  //不是NB联网,显示地址
        address: '',
        projeSetInt: null,
        checkItem: {},
        putList: [],
        projectState: "",
        passVisible: false,
        password: "",
        alarmProjectId: "",
        valueVisible: false,
        triggerValue: "",
        valueName: "",
        triggerPage: {
          size: 10,
          current: 1,
          planId: "",
          projectId: "",
          total: 0,
        },
        triggerVisible: false,
        triggerList: [],
        maxDate: "",
        projectFrom: {
          projectId: "",
          dtuId: "",
          date: "",
        },
        signalVisible: false,
        dtuSnForm: {},
        dtuSn: "",
        snVisible: false,
        canvasWidth: "1280px",
        simVisible: true,
        cameraList: [],
        simform: [],
        radio: [
          {
            value: 0,
            names: "至多一次",
          },
        ],
        projectDetails: {
          deviceId: "",
          thirdSn: "",
          dtus: [],
          planName: "",
        },
        details: false,
        select: "",
        hmiKey: "",
        charts: null,
        eidtForm: {
          projectId: "",
          projectName: "",
          remark: "",
          topicPub: "",
          topicSub: "",
        },
        mapSearchDefault: "天安门广场",
        vStatus: 0,
        projectState: 0,
        signal: 0,
        // projectData: {},
        currentPName: "项目详情",
        pShow: false,
        constant: { PROJECT_IMG, OSS_URL, SYSTEM_IMG },
        theme: { TABLE_HEADER_COLOR },
        map: null,
        amapManager,
        zoom1: 16,
        center1: [116.397972, 39.906901],
        center: [116.397972, 39.906901],
        lng: 0,
        lat: 0,
        contactArr: [],
        loaded: false,
        markers: [
          {
            position: [116.397972, 39.906901],
            events: {
              init(o) {
                that.markerRefs.push(o);
              },
            },
          },
        ],
        markerRefs: [],
        markerRefs1: [],
        markers1: [],
        searchOption: {
          city: "杭州",
          citylimit: false,
        },
        plugin: [
          {
            pName: "Geolocation",
            events: {
              init(o) {
                o.getCityInfo((status, result) => {
                  status;
                  if (result) {
                    that.$nextTick(() => {
                      that.center = result.center;
                    });
                  }
                });
              },
            },
          },
        ],
        events: {
          init(o) {
            setTimeout(() => {
              let cluster = new AMap.MarkerClusterer(o, that.markerRefs, {
                gridSize: 80,
                renderCluserMarker: that._renderCluserMarker,
              });
              cluster;
            }, 1000);
          },
          complete: () => {
            //地图首次加载完成 并完成覆盖物加载 没有参数 只有map有这个事件
          },
          click: (event) => {
            event;
            //事件 event.target是组件实例 可以使用api方法
          },
        },
        projectGroupDetail: {
          projectGroupId: "",
          projectGroupName: "",
          projectGroupAddr: "",
          longitude: 0,
          latitude: 0,
          remark: "",
        },
        projectGroupEditObj: {},
        // pgData: {
        //   projectGroupId: "",
        //   projectGroupName: "",
        //   projectGroupAddr: "",
        //   longitude: 0,
        //   latitude: 0,
        //   remark: "",
        // },
        projectGroupList: [],
        rules: {
          topicPub: [{
            required: true, message: '发布主题不能为空', trigger: 'blur'
          }, {
            min: 1, max: 64, message: '最大长度64位', trigger: 'blur'
          }],
          projectName: [{
            required: true, message: '设备名称不能为空', trigger: 'blur'
          }],
          projectGroupName: [
            { required: true, message: "请输入项目组名称", trigger: "blur" },
            {
              pattern: reg.nameR,
              message: "设备名称最长15位",
              trigger: "blur",
            },
          ],
          deviceId: [{ required: true, message: '设备ID不能为空', trigger: 'blur' },{min:0,max:32,message:'最大长度32位',trigger:'blur'}],
          topicSub: [{ min: 0, max: 64, message: '最大长度64位', trigger: 'blur' }],
          productId: [{min:0,max:32,message:'最大长度32位',trigger:'blur'}],
          thirdSn: [{min:0,max:32,message:'最大长度32位',trigger:'blur'}],
        },
        pjForm: {
          planId: "",
          projectGroupId: number,
          projectName: "",
          remark: "",
          vos: [],
          productId: "",
          thirdSn: "",
          deviceId: "",
          topicPub: "",
          pubQos: 0,
          topicSub: "",
          subQos: 0,
        },
        vos: {},
        pjData: {
          projectGroupId: "",
          projectName: "",
          size: 20,
          current: 1,
          total: 0,
        },
        userForm: {},
        projectPage: [],
        projectList: [],
        planList: [],
        userList: [],
        gatewayList: [],
        dialogVisibile: false,
        bindingDialog: false,
        projectDialog: false,
        userVisibile: false,
        isCreate: true,
        formTitle: "",
        projectAddr: "",
        projectTime: "",

        plan: {},
        userArr: [],
        selectUserId: 0,
        activeName: "1",
        projectGroupArr: [],
        pTotal: 0,
        pData: {
          current: 1,
          size: 8,
          projectGroupName: "",
          createId: "",
          projectGroupId: "",
        },
        selectGroup: "",
        changeForm: {
          userId: "",
          projectIds: [],
        },
        changeUser: {
          projectGroupId: "",
          projectIds: [],
        },
        projectArr: [],
        projectGroupChangeArr: [],
        projectGroupChangeArr1: [],
        projectDetail: {},
        project: {},
        wx: null,
        itv: null,
        pickerOptions: {
          disabledDate(time) {
            return time.getTime() > Date.now();
          },
        },
        getHistory(data, date, ref) {
          if (data.data) {
            let arrData = []
          data.data.forEach((ite) => {
            let newArr = []
            for (let key in ite) {
              newArr.push(key, ite[key])
            }
            arrData.push(newArr)
          })
          this.$set(data, 'data', arrData)
          }
          let option = {
            tooltip: {
              trigger: "axis",
            },
            toolbox: {
              feature: {
                saveAsImage: {},
              },
              right: 20,
              top: 20,
            },
            legend: {
              right: 0,
              top: "50%",
              orient: "vertical",
            },
            grid: {
              y: 50,
              y2: 50,
              x: 100,
              x2: 100,
            },
            xAxis: {
              type: "category",
              boundaryGap: false,
              data: date,
            },
            yAxis: {
              type: "value",
              nameLocation: "end",
              boundaryGap: [0, 0],
            },
            dataZoom: [
              {
                type: "inside",
                start: 0,
                end: 100,
              },
            ],
            series: [
              {
                name: data.name,
                type: "line",
                smooth: true,
                symbol: 'emptyCircle',
                data: data.data,
              },
            ],
          };
          var chartDom = document.getElementById(ref);
          let myChart = echarts.init(chartDom);
          myChart.clear();
          myChart.setOption(option);
        },
      };
    },
    filters: {
      getLeft(index) {
        return that.simform[index].left;
      },
      getCardStatus(index) {
        return that.simform[index].cardStatus;
      },
      getValiddate(index) {
        return that.simform[index].validdate;
      }
    },
    methods: {
      //获取设备地址
      getAddress(sn, states) {
        this.$api.project.getAddress(sn).then((res) => {
          if (res.code == 200) {
            this.address = res.data
            if (states == 1) {
              this.$message.success('刷新成功')
            }
          }
        })
      },
      triggerClose(done) {

        this.$confirm('确认关闭？')
          .then(_ => {
            done();
          })
          .catch(_ => { });

      },
      //校验密码是否正确
      check() {
        if (that.password.toLowerCase() == "china") {
          that.putList = [];
          that.getTrigger();
          that.passVisible = false;
        } else {
          that.passVisible = false;
        }
        that.password = "";
      },
      // 当前是否启用
      changeSelect(value, row) {
        let a = 0;
        that.triggerList.forEach((item) => {
          if (row.alarmProjectId == item.alarmProjectId) {
            item.disType = value;
          }
        });
        that.putList.forEach((item) => {
          if (row.alarmProjectId == item.alarmProjectId) {
            a = 1;
            item.disType = value;
          }
        });
        if (a == 0) {
          let data = {
            disType: value,
            alarmProjectId: row.alarmProjectId,
          };
          that.putList.push(data);
        }

        // that.upProAlarm();
      },
      // 获取要修改的数据
      setValue(row, name) {
        that.checkItem = row
        that.triggerValue = row[name];
        that.valueName = name;
        that.alarmProjectId = row.alarmProjectId;
        that.valueVisible = true;
      },
      triggerSizeChange(val) {
        that.$set(that.triggerPage, "size", val);
        that.$set(that.triggerPage, "current", 1);
        that.getTriggerList();
      },
      triggerCurrentChange(val) {
        that.$set(that.triggerPage, "current", val);
        that.getTriggerList();
      },
      valueConfig(done) {
        //  this.$confirm('确认关闭？')
        //     .then(_ => {
        //       done();
        //     })
        //     .catch(_ => {});
      },
      //发送更新配置请求
      updateConfig() {
        let data = {
          planId: that.projectDetail.planId,
          projectId: that.projectDetail.projectId,
        };
        that.$api.project.updateConfig(data).then((res) => {
          if (res.code == 200) {
            that.triggerVisible = false;
          }
        });
      },
      proAlarm() {
        let data;
        let e = that.triggerValue;
        if (that.checkItem.paramTypeId == 3 || that.checkItem.paramTypeId == 4) {
          for (let i = 0; i < that.checkItem.typeValue; i++) {
            e = e * 10
          }
          if (e % 1 > 0) {
            that.$message({
              type: "error",
              message: `小数最多保留${that.checkItem.typeValue}位！`,
            })
            return
          }
        } else {
          if (e % 1 > 0) {
            that.$message({
              type: "error",
              message: `只能输入整数！`,
            })
            return
          }
        }

        that.triggerList.forEach((element) => {
          if (element.alarmProjectId == that.alarmProjectId) {

            element[that.valueName] = that.triggerValue;

          }
        });
        let a = 0;
        that.putList.forEach((item) => {
          if (that.alarmProjectId == item.alarmProjectId) {
            a = 1;
            item[that.valueName] = that.triggerValue;
          }
        });
        if (a == 0) {
          if (that.valueName == "aValue") {
            data = {
              aValue: that.triggerValue,
              alarmProjectId: that.alarmProjectId,
            };
          } else if (that.valueName == "xValue") {
            data = {
              xValue: that.triggerValue,
              alarmProjectId: that.alarmProjectId,
            };
          }
          that.putList.push(data);
        }

        that.valueVisible = false;
      },
      upProAlarm() {
        that.$api.project.proAlarm(that.putList).then((res) => {
          if (res.code == 200) {
            that.updateConfig();
            that.getTriggerList();
          }
        });
      },
      // 获取触发条件列表弹框
      getTrigger() {
        that.triggerPage.planId = that.projectDetail.planId;
        that.triggerPage.projectId = that.projectDetail.projectId;
        that.getTriggerList();
        that.triggerVisible = true;
      },
      // 拉取告警列表
      getTriggerList() {
        that.$api.project.getAlarmPage(that.triggerPage).then((res) => {
          if (res.code == 200) {
            that.triggerList = res.data.records;
            that.triggerPage.total = res.data.total;
          }
        });
      },

      getSignal(item, projectDetail) {
        that.projectFrom.projectId = projectDetail.projectId;
        that.projectFrom.dtuId = item.dtuId;
        let date = new Date();
        let month =
          date.getMonth() + 1 < 10
            ? "0" + (date.getMonth() + 1)
            : date.getMonth() + 1;
        let dates = date.getDate() < 10 ? "0" + date.getDate() : date.getDate();
        that.maxDate = date.getFullYear() + "-" + month + "-" + dates;
        that.projectFrom.date = date.getFullYear() + "-" + month + "-" + dates;
        that.signalVisible = true;
        that.setSignalHistory();
      },
      setSignalHistory() {
        that.$api.project.getCsqHistoryData(that.projectFrom).then((res) => {
          if (res.code == 200) {
            that.getHistory(res.data[0].data[0], res.data[0].date, "dtuSignal");
          }
        });
      },

      // 根据sn查询项目详情
      getDetails() {
        if (!that.searchData.projectName) {
          that.$message({
            type: "warning",
            message: "请输入SN或者设备名称!",
          });
          return
        }
        const loading = this.$loading({
          lock: true,
          text: '加载中,请稍后...',
          spinner: 'el-icon-loading',
          background: 'rgba(0, 0, 0, 0.7)'
        });
        that.$api.projectGroup.searchList(that.searchData).then((res) => {
          loading.close()
          if (res.code == 200) {
            that.deviceList = true
            that.tableData = res.data.records
            that.searchData.total = res.data.total
          }
        })
      },
      searchDtails(dtusn) {
        const loading = this.$loading({
          lock: true,
          text: '加载中，请稍候...',
          spinner: 'el-icon-loading',
          background: 'rgba(0, 0, 0, 0.7)'
        });
        that.$api.dtu.details(dtusn).then((res) => {
          loading.close()
          that.deviceList = false
          that.searchData.projectName = ''
          if (res.code == 200) {
            that.dtuSnForm = res.data;
            that.snVisible = true;
            that.$set(that.pData, "createId", res.data.userId);
            if (res.data.userId) {
              that.selectGroup = res.data.projectGroupId;
              that.pjData.projectName = res.data.projectName;
              that.getGroupByUser();
              that.getProjectPage();
            }
          }
        });
      },
      handleSizeChange(val) {
        that.searchData.size = val
        that.getDetails()
      },
      handleCurrentChange(val) {
        that.searchData.current = val
        that.getDetails()
      },
      monitorClick: function (obj) {
        window.open(
          visitUrl +
          "#/openCamera?deviceSerial=" +
          obj.deviceSerial +
          "&validateCode=" +
          obj.validateCode +
          "&ysId=" +
          obj.ysId +
          "&channelNo=" +
          obj.channelNo
        );
      },
      getCameraList(projectId) {
        that.$api.camera.getCamera(projectId).then((res) => {
          that.cameraList = res.data;
        });
      },
      getSIM(item, index) {
        this.simVisible = false;
        that.$api.projectGroup.getMobile(item).then((res) => {
          this.simVisible = true;
          if (res.code == 200) {
            [that.simform[index]] = [res.data];
          }

        });
      },
      tabClick: function () {
        if (that.activeName == 2) {
          that.getProjectGroup(that.userId)
          that.drawPie();
        }
      },
      closeDialog: function () {
        that.pShow = false;
        clearInterval(that.itv);
        that.itv = null;
        that.getProjectPage();
      },
      getStatus: function (projectState) {
        let obj = {
          style: null,
          content: null,
        };
        switch (projectState) {
          case 1:
            obj = {
              style: { "background-color": "#1eaa39" },
              content: "正常",
            };
            break;
          case 2:
            obj = {
              style: { "background-color": "rgba(180,180,181)" },
              content: "离线",
            };
            break;
          case 3:
            obj = {
              style: { "background-color": "#e50012" },
              content: "报警",
            };
            break;
          case 4:
            obj = {
              style: { "background-color": "#f29500" },
              content: "故障",
            };
            break;
          default:
            break;
        }

        that.project = obj;
      },
      closed: function () {
        that.$store.commit("setLoading", true);
        // that.closeWebSocket(that.projectData.projectId);
        // gWebSocket.closeWebSocket();
      },
      changeGroup: function (item) {
        that.$set(that.pjData, "current", 1);
        that.$set(that.pjData, "projectGroupId", item.projectGroupId);
        that.selectGroup = item.projectGroupId;
        window.localStorage.setItem("selectGroup", that.selectGroup);
        that.getProjectPage();
        that.getProjectGroup(that.selectGroup);
        that.$set(that.pData, "projectGroupId", item.projectGroupId);
        that.center1 = [item.longitude, item.latitude];
        that.markers1 = [
          {
            position: [item.longitude, item.latitude],
            events: {
              init(o) {
                that.markerRefs1.push(o);
              },
            },
          },
        ];
      },
      openProject: function (projectId, planId, item) {
        // that.projectData = {
        //   projectId: projectId,
        //   planId: planId,
        // };
        this.$store.commit("setProjectId", projectId);
        this.$store.commit("setPId", planId);
        this.$store.commit("setPlatType", "pc");
        if (item.serverType == 0) {
          that.simform = [];
          that.getProjectDetail(projectId);
          that.getCameraList(projectId);
        } else if (item.serverType == 1) {
          this.details = true;
          this.$api.project.getProject(item.projectId).then((res) => {
            this.projectDetails = res.data;
            that.isCreate = false;
            // console.log(JSON.stringify(this.projectDetails));
          });
        }

        // window.open(
        //   `${addressUrl}#/hmiexecute/${that.$store.state.token}/${that.$store.state.tokenId}/${planId}/${projectId}`
        // );
      },
      //点击搜索后触发的事件
      onSearchResult(pois) {
        that.center = [pois[0].lng, pois[0].lat];
        that.$set(that.projectGroupEditObj, "latitude", pois[0].lat);
        that.$set(that.projectGroupEditObj, "longitude", pois[0].lng);
        that.$set(that.projectGroupEditObj, "projectGroupAddr", pois[0].name);
        that.markers = [
          {
            position: [pois[0].lng, pois[0].lat],
            events: {
              init(o) {
                that.markerRefs.push(o);
              },
            },
          },
        ];
      },
      _renderCluserMarker(context) {
        const count = that.markers.length;
        let factor = Math.pow(context.count / count, 1 / 18);
        let div = document.createElement("div");
        let Hue = 180 - factor * 180;
        let bgColor = "hsla(" + Hue + ",100%,50%,0.7)";
        let fontColor = "#FFFFFF";
        let borderColor = "rgba(0,0,0,0)";
        let shadowColor = "hsla(" + Hue + ",100%,50%,0.7)";
        div.style.backgroundColor = bgColor;
        div.setAttribute("class", "twinkling");
        let size = Math.round(30 + Math.pow(context.count / count, 1 / 5) * 20);
        div.style.width = div.style.height = size + "px";
        div.style.border = "solid 1px " + borderColor;
        div.style.borderRadius = size / 2 + "px";
        div.style.boxShadow = "0px 0px 15px " + shadowColor;
        div.innerHTML = context.count + "个";
        div.style.lineHeight = size + "px";
        div.style.color = fontColor;
        div.style.fontSize = "10px";
        div.style.textAlign = "center";
        context.marker.setOffset(new AMap.Pixel(-size / 2, -size / 2));
        context.marker.setContent(div);
      },
      filterMethod() { },
      contactList: function () {
        that.$api.projectGroup.getContact().then((res) => {
          if (200 == res.code) {
            that.contactArr = res.data;
          }
        });
      },
      selectProjectGroup(v) {
        that.$set(that.pjData, "projectGroupId", v);
        that.getProjectPage();
      },
      getGroupByUser(isCreate = true) {
        that.$api.projectGroup.productPageByUser(that.pData).then((res) => {
          if (200 == res.code) {
            that.projectGroupArr = res.data.records;
            if (res.data.records.length > 0) {
              //项目组详情
              if (isCreate) {
                let id
                if (!window.localStorage.getItem("selectGroup")) {
                  id = that.projectGroupArr[0].projectGroupId;
                } else {
                  id = window.localStorage.getItem("selectGroup") * 1;
                }
                that.getProjectGroup(id);
                that.$set(that.projectGroupDetail, "projectGroupId", id);
                that.$set(that.pjForm, "projectGroupId", id);
                that.$set(that.pjData, "projectGroupId", id);
                that.pTotal = res.data.total;
                that.selectGroup = id;
              }
              that.getProjectPage();
              // that.$set(that.pData, "projectGroupId", id);
            }
          }
        });
      },
      getProjectGroup(id) {
        that.userId = id
        that.$api.projectGroup.getProjectGroup(id).then((response) => {
          if (response.code == 200) {
            // that.markers1=[]
            that.projectGroupDetail = response.data;
            that.center1 = [response.data.longitude, response.data.latitude];
            that.markers1 = [{
              position: [response.data.longitude, response.data.latitude],
              events: {
                init(o) {
                  that.markerRefs1.push(o);
                },
              },
            }];
            // that.markers1.push({
            //   position: [response.data.longitude, response.data.latitude],
            //   events: {
            //     init(o) {
            //       that.markerRefs1.push(o);
            //     },
            //   },
            // });
            clearTimeout(that.projeSetInt);
            that.projeSetInt = null;

            //  that.projeSetInt= setTimeout(() => {
            //       that.getProjectGroup(id);
            //     }, 10000);
          }
        });
      },
      getUserNoPage: function () {
        that.$set(that.pData, "createId", window.localStorage.getItem("cId") * 1);
        that.getGroupByUser();
      },
      addProjectGroup() {
        new Promise((resolve) => {
          that.contactList();
          resolve();
        }).then(() => {
          that.formTitle = "新增项目组";
          that.dialogVisibile = true;
          that.isCreate = true;
          that.center = [116.397972, 39.906901];
          that.markers = [
            {
              position: [116.397972, 39.906901],
              events: {
                init(o) {
                  that.markerRefs.push(o);
                },
              },
            },
          ];
        });
      },
      editProjectGroup(obj) {
        that.projectGroupEditObj = Object.assign({}, obj);
        that.dialogVisibile = true;
        that.isCreate = false;
        that.contactList();
        that.formTitle = "修改项目组";
        that.mapSearchDefault = that.projectGroupDetail.projectGroupAddr;
        that.center = [
          that.projectGroupDetail.longitude,
          that.projectGroupDetail.latitude,
        ];
        that.markers = [
          {
            position: [
              that.projectGroupDetail.longitude,
              that.projectGroupDetail.latitude,
            ],
            events: {
              init(o) {
                that.markerRefs.push(o);
              },
            },
          },
        ];
      },
      beforeClose: function () {
        that.dialogVisibile = false;
        that.projectGroupEditObj = {};
        that.mapSearchDefault = "天安门广场";
        that.center = [116.397972, 39.906901];
        that.markers = [
          {
            position: [116.397972, 39.906901],
            events: {
              init(o) {
                that.markerRefs.push(o);
              },
            },
          },
        ];
      },
      groupSubmitForm(status) {
        that.$set(that.projectGroupEditObj, "contactsIds", that.projectGroupEditObj.contactsIds ? that.projectGroupEditObj.contactsIds : [])
        that.$refs["projectGroupRef"].validate((valid) => {
          if (status == 1) {
            let _api = that.isCreate
              ? that.$api.projectGroup.addProjectGroup(that.projectGroupEditObj)
              : that.$api.projectGroup.editProjectGroup(that.projectGroupEditObj);
            _api.then((response) => {
              if (response.code == 200) {
                that.$refs.projectGroupRef.resetFields();
                that.getProjectGroup(that.selectGroup);
                that.$message({
                  type: "success",
                  message: response.msg,
                });
                that.beforeClose()
                that.dialogVisibile = false;
                that.getGroupByUser(that.isCreate);
                that.isCreate = true;
                that.mapSearchDefault = "天安门广场";
                that.center = [116.397972, 39.906901];
                that.markers = [
                  {
                    position: [116.397972, 39.906901],
                    events: {
                      init(o) {
                        that.markerRefs.push(o);
                      },
                    },
                  },
                ];
              }
            });
          } else {
            if (valid) {
              // that.projectGroupEditObj.projectGroupAddr=that.mapSearchDefault
              let _api = that.isCreate
                ? that.$api.projectGroup.addProjectGroup(that.projectGroupEditObj)
                : that.$api.projectGroup.editProjectGroup(that.projectGroupEditObj);
              _api.then((response) => {
                if (response.code == 200) {
                  that.$refs.projectGroupRef.resetFields();
                  that.getProjectGroup(that.selectGroup);
                  that.$message({
                    type: "success",
                    message: response.msg,
                  });
                  that.dialogVisibile = false;
                  that.beforeClose()
                  that.getGroupByUser(that.isCreate);
                  that.isCreate = true;
                  that.mapSearchDefault = "天安门广场";
                  that.center = [116.397972, 39.906901];
                  that.markers = [
                    {
                      position: [116.397972, 39.906901],
                      events: {
                        init(o) {
                          that.markerRefs.push(o);
                        },
                      },
                    },
                  ];
                }
              });
            }
          }

        });
      },
      delProjectGroup(pgId) {
        that
          .$confirm("确认删除", "提示", {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
          })
          .then(() => {
            that.$api.projectGroup.delProjectGroup(pgId).then((response) => {
              if (response.code == 200) {
                that.$message({
                  type: "success",
                  message: "提交成功",
                });
                that.getGroupByUser();
                // that.getProjectPage();
              }
            });
          });
      },
      getPlanList() {
        that.$api.plan.getPlanList().then((response) => {
          if (200 == response.code) {
            that.planList = response.data;
          }
        });
      },
      async getGatewayList(planId) {
        that.planList.forEach((element) => {
          if (planId == element.planId) {
            this.select = element;
          }
        });
        await that.$api.gateway.getGatewayList(planId).then((response) => {
          if (200 == response.code) {
            that.gatewayList = response.data;
          }
        });
        if (this.select.serverId) {
          await that.$api.gateway.getServer(this.select.serverId).then((res) => {
            that.$set(that.pjForm, 'topicPub', res.data.topicPub)
            that.$set(that.pjForm, 'topicSub', res.data.topicSub)
          })
        }
      },
      closeBind(done) {
        done();
      },
      closeBinding(done) {
        done();
        that.$refs.projectForm.resetFields();
        that.pjForm = {};
        that.projectAddr = "";
        that.projectTime = "";
        that.vos = {};
      },
      selectProject() {
        that.$set(that.pjData, "current", 1);
        that.$set(
          that.pjData,
          "projectGroupId",
          that.projectGroupDetail.projectGroupId
        );
        that.getProjectPage();
      },
      projectSizeChange(val) {
        that.$set(that.pjData, "size", val);
        that.$set(that.pjData, "current", 1);
        that.getProjectPage();
      },
      projectCurrentChange(val) {
        that.$set(that.pjData, "current", val);
        that.getProjectPage();
      },
      checkGroup: function () {
        that.$set(that.pData, "current", 1);
        that.getGroupByUser();
      },

      getProjectPage() {
        that.$api.project.getProjectPage(that.pjData).then((res) => {
          if (200 == res.code) {
            that.projectPage = res.data.records;
            that.pjData.total = res.data.total;

          }
        });
      },
      addProject() {
        that.gatewayList = [];
        that.vos = {};
        that.pjForm = {
          planId: "",
          projectGroupId: null,
          projectName: "",
          remark: "",
          vos: [],
          productId: "",
          thirdSn: "",
          deviceId: "",
          topicPub: "",
          pubQos: 0,
          topicSub: "",
          subQos: 0,
        };
        new Promise((resolve) => {
          that.getPlanList();
          resolve();
        }).then(() => {
          that.bindingDialog = true;
          that.isCreate = true;
          that.formTitle = "新增设备";
        });
      },
      editProject(pjId) {
        that.$api.projectGroup.getProject(pjId).then((response) => {
          if (response.code == 200) {
            that.eidtForm = response.data;
            that.bindingDialog = true;
            that.isCreate = false;
            that.formTitle = "修改设备";
          }
        });
      },
      submitProjectForm() {

        if (this.isCreate) {
          that.$refs["projectForm"].validate((valid) => {
            if (valid) {
              let _dtus = [];
              for (let key in that.vos) {
                _dtus.push({
                  gatewayId: key,
                  dtuSn: that.vos[key],
                });
              }
              that.$set(that.pjForm, "vos", _dtus);
              that.$set(
                that.pjForm,
                "projectGroupId",
                that.projectGroupDetail.projectGroupId
              );
              // that.$set(
              //   that.pjForm,
              //   "startTime",
              //   that.projectTime ? that.projectTime[0] : ""
              // );
              // that.$set(
              //   that.pjForm,
              //   "endTime",
              //   that.projectTime ? that.projectTime[1] : ""
              // );

              let _api = that.$api.project.addProject(that.pjForm);
              _api.then((response) => {
                if (response.code == 200) {
                  that.$refs.projectForm.resetFields();
                  that.$message({
                    type: "success",
                    message: response.msg,
                  });
                  that.isCreate = true;
                  that.bindingDialog = false;
                  that.getProjectPage(that.pjData);
                }
              });
            }
          });
        } else {
          // if (that.details) {
          //   that.eidtForm.projectId=that.projectDetails.projectId;
          //   that.eidtForm.projectName=that.projectDetails.projectName;
          //   that.eidtForm.remark=that.projectDetails.remark;
          //   that.eidtForm.topicPub=that.projectDetails.topicPub;
          //   that.eidtForm.topicSub=that.projectDetails.topicSub;
          // }
          let from = {
            "projectId": that.eidtForm.projectId,
            "projectName": that.eidtForm.projectName,
            "remark": that.eidtForm.remark,
          }
          if (that.eidtForm.topicPub) {
            from["topicPub"] = that.eidtForm.topicPub;
            from["topicSub"] = that.eidtForm.topicSub;
          }
          let _api = that.$api.project.editProject(from);
          _api.then((response) => {
            if (response.code == 200) {
              if (!that.details) {
                that.$refs.projectForm1.resetFields();
              }
              that.$message({
                type: "success",
                message: response.msg,
              });
              that.bindingDialog = false;
              that.getProjectPage(that.pjData);
            }
          });
        }
      },
      delProject(pjId) {
        that
          .$confirm("解绑后历史数据会被清空，谨慎操作！", "提示", {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
          })
          .then(() => {
            that.$api.project.delProject(pjId).then((response) => {
              if (response.code == 200) {
                that.$message({
                  type: "success",
                  message: "提交成功",
                });
                that.getProjectPage(that.pjData);
              }
            });
          });
      },
      distributeProject() {
        that.projectDialog = true;
        that.formTitle = "分配项目";
        that.getProjectGroupNoPage();
        that.getProjectNoPageByAccount();
      },
      distributeUser() {
        that.userVisibile = true;
        that.formTitle = "分配用户";
        // that.getChildProjectGroupNoPage();
        that.getProjectNoPageByAccount();
      },
      getProjectNoPageByAccount: function () {
        that.$api.projectGroup
          .getProjectNoPage(that.selectGroup)
          .then((response) => {
            if (200 == response.code) {
              that.projectArr = response.data;
            }
          });
      },
      getProjectGroupNoPage: function () {
        that.$api.projectGroup
          .getProjectGroupNoPage(that.$store.state.userId)
          .then((response) => {
            if (200 == response.code) {
              that.projectGroupChangeArr = response.data;
            }
          });
      },
      // getChildProjectGroupNoPage: function () {
      //   that.$api.projectGroup.getChildUserNoPage().then((response) => {
      //     if (200 == response.code) {
      //       that.projectGroupChangeArr1 = response.data;
      //     }
      //   });
      // },
      submitDis: function (data) {
        that
          .$confirm("确定要分配嘛？", "提示", {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
          })
          .then(() => {
            that.$api.projectGroup.distributeSubmit(data).then((response) => {
              if (200 == response.code) {
                that.changeForm = {
                  projectGroupId: "",
                  projectIds: [],
                };

                that.changeUser = {
                  projectGroupId: "",
                  projectIds: [],
                };

                that.projectDialog = false;
                that.userVisibile = false;
                that.getProjectPage();
              }
            });
          });
      },
      submitUser: function (data) {
        that
          .$confirm("确定要分配嘛？", "提示", {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
          })
          .then(() => {
            that.$api.projectGroup.userSubmit(data).then((response) => {
              if (200 == response.code) {
                that.changeForm = {
                  projectGroupId: "",
                  projectIds: [],
                };

                that.changeUser = {
                  projectGroupId: "",
                  projectIds: [],
                };

                that.projectDialog = false;
                that.userVisibile = false;
                that.getProjectPage();
              }
            });

          });
      },
      getWidth: function () {
        return 71 + "%";
      },
      // 得到项目详情
      getProjectDetail: function (projectId) {
        const loading = this.$loading({
          lock: true,
          text: '加载中，请稍后...',
          spinner: 'el-icon-loading',
          background: 'rgba(0, 0, 0, 0.7)'
        });
        that.$api.projectGroup.getProject(projectId).then((response) => {
          loading.close()
          if (200 == response.code) {
            that.projectDetail = response.data;
            that.getAddress(that.projectDetail.dtus[0].dtuSn)
            that.getStatus(that.projectDetail.projectState);
            that.currentPName = that.projectDetail.projectName;
            that.hmiKey = new Date().getTime();
            that.pShow = true;
            for (let i = 0; i < response.data.dtus.length; i++) {
              let obj = { left: "**", cardStatus: "**", validdate: "**", };
              that.simform.push(obj);
            }
            that.openV(projectId);
            clearInterval(that.itv);
            that.itv = null;
            that.itv = setInterval(function () {
              that.getV(projectId);
            }, 5000);
          }
        });
      },
      // 得到项目详情
      getV: function (projectId) {
        that.$api.projectGroup.checkV(projectId).then((response) => {
          if (200 == response.code) {
            that.vStatus = response.data.fastState;
            that.projectState = response.data.projectState;
            that.signal = response.data.signal;
            that.getStatus(response.data.projectState);
          }
        });
      },
      openV: function (projectId) {
        that.$api.projectGroup.openV(projectId).then((response) => {
          if (200 == response.code) {
            // that.vStatus = response.data;
            that.getV(projectId);
          }
        });
      },
      drawPie: function () {
        let data = [
          ["正常设备", that.projectGroupDetail.normalCount],
          ["离线设备", that.projectGroupDetail.offlineCount],
          ["报警设备", that.projectGroupDetail.alarmCount],
          ["故障设备", that.projectGroupDetail.faultCount],
        ];
        let option = {
          title: {
            text: "",
          },
          chart: {
            type: "pie",
            options3d: {
              enabled: true,
              alpha: 45,
              beta: 0,
            },
          },
          tooltip: {
            pointFormat: "{series.name}: <b>{point.percentage:.1f}%</b>",
          },
          plotOptions: {
            pie: {
              allowPointSelect: true,
              cursor: "pointer",
              depth: 35,
              dataLabels: {
                enabled: false,
                format: "{point.name}",
              },
              colors: ["#72dd74", "#d8d8d8", "#F56C6C", "#e6a23c"],
            },
          },
          series: [
            {
              type: "pie",
              name: "项目台数",
              data: data,
            },
          ],
          options3d: {
            enabled: false, // 是否启用 3D 功能，默认是 false，设置为 true 开启 3D 功能
            alpha: 0, // 内旋转角度
            beta: 0, // 外旋转角度
            depth: 100, // 图表的全深比，即为3D图X，Y轴的平面点固定，以图的Z轴原点为起始点上
            // 下旋转，值越大往外旋转幅度越大，值越小往内旋转越大
            viewDistance: 100, // 视图距离，它对于计算角度影响在柱图和散列图非常重要。此值不能用于3D的饼图
            frame: {
              // Frame框架，3D图包含柱的面板，我们以X ,Y，Z的坐标系来理解，X轴与 Z轴所形成
              // 的面为bottom，Y轴与Z轴所形成的面为side，X轴与Y轴所形成的面为back，bottom、
              // side、back的属性一样，其中size为感官理解的厚度，color为面板颜色
              bottom: {
                size: 1,
                color: "transparent",
              },
              side: {
                size: 1,
                color: "transparent",
              },
              back: {
                size: 1,
                color: "transparent",
              },
            },
          },
          credits: {
            enabled: false,
          },
        };
        that.$highcharts.chart(that.$refs.pie, option);
      },
      // 级联查找
      getlazyLoad(node, resolve) {
        const { level } = node;
        let type = level == 0 ? that.$store.state.userId : node.value
        const nodes = []
        // if (level == 1 && type == that.$store.state.userId) {
        //   node.loading = false
        //   node.loaded=true
        //   return
        // }
        that.$api.projectGroup.searchUserChild(type).then((res) => {
          if (res.code == 200) {
            node.loading = false
            node.loaded = true
            if (res.data.length > 0) {
              res.data.map((item) => {
                let obj = {
                  value: item.userId,
                  label: item.fullName,
                  leaf: !item.children
                }
                nodes.push(obj)
              })
              if (level == 0) {
                nodes.unshift({ value: that.$store.state.userId, label: that.$store.state.name, leaf: true })
                that.options = nodes
              }
            }
            resolve(nodes);
          }
        })
      },
      userChange(val) {
        document.querySelectorAll(".el-cascader-node__label").forEach(el => {
          el.onclick = function () {
            if (this.previousElementSibling) this.previousElementSibling.click();
          };
        });
        that.pjData.projectName = ''
        that.pData.createId = val
        if (!val) return
        window.localStorage.setItem("cId", that.pData.createId);
        window.localStorage.setItem("selectGroup", "")
        if (this.timers) {
          clearTimeout(this.timers)
          this.timers = null
        }
        this.timers = setTimeout(() => {
          that.getGroupByUser();
        }, 200)
        that.activeName = "1";
      },
    },
    beforeMount() {
      that = this;
    },
    mounted() {
      this.zoom = 1 / document.body.style.zoom;
      window.addEventListener('resize', () => {
        this.zoom = 1 / document.body.style.zoom;
      })
      that.$nextTick(() => {
        that.getUserNoPage();
      })
      // that.getChildProjectGroupNoPage()
      this.currentTime = new Date().toLocaleDateString()
    },
    beforeDestroy() {
      clearInterval(that.itv);
      that.itv = null;

      clearTimeout(that.projeSetInt);
      that.projeSetInt = null;
    },
    beforeRouteLeave(to, from, next) { //离开之前清空请求
      this.$store.commit('clearCancelToken')
      next()
    }
  };
</script>

<style scoped>
  .servers {
    text-align: center;
    font-size: 20px;
    font-weight: bold;
    color: red;
    margin-top: 20px;
  }

  .el-select,
  .el-input,
  .el-input-number {
    width: 250px;
  }

  .el-input-number>>>input {
    text-align: left;
  }

  .project-group-select-btn {
    margin-top: 7px;
  }

  .project-group-add-btn {
    float: right;
    margin-right: -18px;
    /* margin: 7px 30px; */
  }

  .project-group-main {
    position: relative;
    height: calc(100% - 40px);
    margin-top: 20px;
    padding: 5px;
  }

  .project-group-main>>>.el-card__header {
    padding: 0 18px;
  }

  .iot-tab {
    height: calc(100% - 50px);
  }

  .el-tabs>>>.el-tabs__content {
    height: calc(100% - 50px) !important;
    overflow-y: auto;
  }

  .el-tabs>>>.el-tabs__content::-webkit-scrollbar {
    /*滚动条整体样式*/
    width: 10px;
    /*高宽分别对应横竖滚动条的尺寸*/
    height: 1px;
  }

  .el-tabs>>>.el-tabs__content::-webkit-scrollbar-thumb {
    /*滚动条里面小方块*/
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0);
    background: rgba(0, 0, 0, 0);
  }

  .el-tabs>>>.el-tabs__content::-webkit-scrollbar-track {
    /*滚动条里面轨道*/
    -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0);
    border-radius: 10px;
    background: rgba(0, 0, 0, 0);
  }

  .project-group-body {
    /* border-radius: 5px; */
    line-height: 30px;
    padding: 10px;
    /* box-shadow: 0 2px 12px 0 rgb(0 0 0 / 10%); */
    /* border: 5px solid #d9dde488; */
  }

  .project-body {
    width: 80%;
    float: right;
    max-height: 800px;
    flex-wrap: wrap;
    overflow-y: auto;
    overflow-x: hidden;
    background-color: #f5f7fa;
  }

  .project-group-item {
    position: relative;
    height: 130px;
    margin: 10px;
    border: 1px solid #ebeef5;
    border-radius: 3px;
    cursor: pointer;
    display: flex;
    overflow: hidden;
  }

  .remark {
    margin: 5px 5px 5px 0;
    width: 150px;
    font-size: 12px;
    height: 48px;
    overflow: hidden;
    /* 超出的文本隐藏 */
    text-overflow: ellipsis;
    /* 溢出用省略号显示 */
    color: #aaa;
  }

  .project-group-map {
    width: 100%;
    height: 200px;
    margin-top: 10px;
  }

  .project-map {
    width: 100%;
    height: 200px;
  }

  .pg-name-background {
    width: 80%;
    height: 20px;
    border-bottom-right-radius: 100px;
    margin: -20px 0 0 -20px;
    background-color: #ff8f01;
    color: white;
    line-height: 20px;
    padding: 5px 20px;
    display: inline-block;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .pg-name {
    width: 270px;
    color: white;
    position: fixed;
    margin-top: -28px;
  }

  .pg-name:hover {
    max-width: none;
    display: block;
  }

  .project-group-binding-btn {
    float: right;
    margin-right: -15px;
  }

  .pagination {
    width: 97%;
    text-align: right;
  }

  .pagination>>>.btn-next,
  .pagination>>>.btn-prev,
  .pagination>>>.el-pager li,
  .pagination>>>.el-input__inner {
    background-color: rgba(0, 0, 0, 0);
  }

  .add-project-dialog {
    margin-top: -70px;
  }

  .project-form {
    max-height: 650px;
    overflow-y: auto;
  }

  .add-project-dialog>>>.el-input__count {
    bottom: -5px;
    background: none;
  }

  .project-body-item {
    /* display: flex;
  flex-wrap: wrap;
  align-content: space-around; */
    height: 600px;
    padding: 10px;
  }

  .project-item {
    width: 23%;
    height: 100px;
    margin: 10px;
    border: 1px solid #ebeef5;
    border-radius: 3px;
    display: flex;
    cursor: pointer;
    background-color: #ffffff;
    float: left;
    box-shadow: 0 2px 12px 0 rgb(0 0 0 / 10%);
    align-items: center;
  }

  .project-item:hover {
    box-shadow: 0 2px 12px 0 rgb(0 0 0 / 10%);
  }

  .project-item-words {
    width: 65%;
    height: 100%;
    margin-top: 35px;
    /* flex-direction: column;
  align-items: flex-start; */
  }

  .project-item-words h4 .project-item-words p {
    margin-block-start: 0.5em;
    margin-block-end: 0.5em;
  }

  .project-item-words h4 {
    display: block;
    width: 100%;
    height: 38px;
    margin-bottom: 10px;
    text-overflow: -o-ellipsis-lastline;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    line-clamp: 2;
    -webkit-box-orient: vertical;
  }

  .project-item-words p {
    word-break: break-all;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    font-size: 13px;
  }

  .project-item-image {
    /* width: 120px; */
    height: 70%;
    display: flex;
    flex: 1;
    text-align: center;
    justify-content: center;
    /* padding: 10px; */
  }

  .project-item-image img,
  .project-item-image>>>.el-image {
    height: 90%;
  }

  .project-group-btn {
    margin: 20px 0;
    display: flex;
    justify-content: space-around;
  }

  .project-group-btn>>>.el-button--mini {
    padding: 5px 13px;
  }

  .project-group-main>>>.search-btn {
    display: none !important;
  }

  .project-group-main>>>.el-vue-search-box-container {
    width: 250px;
  }

  .project-group-main>>>.el-vue-search-box-container .search-box-wrapper {
    -webkit-appearance: none;
    background-color: #fff;
    background-image: none;
    border-radius: 4px;
    border: 1px solid #dcdfe6;
    box-sizing: border-box;
    color: #606266;
    display: inline-block;
    font-size: inherit;
    height: 40px;
    line-height: 40px;
    outline: 0;
    padding: 0 5px;
    transition: border-color 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
    width: 100%;
  }

  .detailDialog>>>.el-dialog {
    border-radius: 5px;
  }

  .detailDialog>>>.el-dialog__body {
    padding: 0;
  }

  .detailDialogs>>>.el-dialog__body {
    padding: 0 0 60px 0;
  }

  p {
    line-height: 30px;
  }

  p img {
    width: 30px;
    height: 30px;
    float: right;
    margin: -5px 10px 5px 0;
    cursor: pointer;
  }

  .iot-project-status {
    float: right;
    margin-left: 10px;
    width: 40px;
    height: 40px;
    line-height: 40px;
    border-radius: 20px;
    text-align: center;
    font-size: 14px;
    color: #ffffff;
    margin-top: -10px;
    margin-right: 10px;
    cursor: pointer;
    transform: scale(0.75, 0.75);
  }

  .iot-card>>>.el-card__body {
    padding: 15px;
  }

  .iot-search-item>>>.el-form-item__content {
    width: 100%;
    padding-left: 5px;
  }

  .iot-search-item>>>.el-form-item {
    margin-bottom: 15px;
    width: inherit;
  }

  ::v-deep .el-tabs__nav-scroll {
    width: 60%;
    margin: 0 auto;
  }

  .iot-search-input>>>.el-input__inner {
    height: 30px;
    font-size: 10px;
  }

  .iot-tab>>>.el-tabs__header {
    margin: 0;
  }

  .project-group-main>>>.el-button--danger.is-plain,
  .project-group-main>>>.el-tag--danger {
    color: #e6212e;
  }

  .iot-chart-div {
    position: relative;
    width: 100%;
    height: 250px;
    /* display: flex;
  justify-content: space-around; */
  }

  .iot-pie {
    /* display: flex; */
    width: 70%;
    height: 250px;
    display: inline-block;
  }

  .iot-legand-div {
    width: 30%;
    display: inline-block;
    float: right;
  }

  .iot-legand {}

  .iot-legand div {
    display: inline-block;
    width: 25px;
    height: 15px;
    border-radius: 3px;
  }

  .iot-legand span {
    font-size: 10px;
    margin-top: -7px;
    margin-left: 5px;
  }

  .btn {
    /* display: flex; */
    /* justify-content: flex-end; */
    width: 86px;
    /* box-sizing: border-box; */
    padding: 0 20px;
    position: absolute;
    bottom: 0px;
    right: 0;
  }

  .cameraList {
    width: 100%;
    box-sizing: border-box;
    padding: 20px;
  }

  .cameraList div {
    margin: 0 10px;
  }

  .dtus {
    width: 320px;
  }

  .dtus_body {
    width: 100%;
    height: 100%;
    background: #fff;
    margin-bottom: 20px;
  }

  ::v-deep .page .el-pagination__jump {
    margin-left: 5px;
  }

  .dtuBox {
    width: 100%;
    height: 500px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .filterCss {
    filter: grayscale(90%);
  }
  .dtuSignalTitle {
    box-sizing: border-box;
    padding: 0 96px;
  }
  #dtuSignal {
    width: 1440px !important;
    height: 500px !important;
  }
  .editNum {
    color: rgb(64, 158, 255);
    cursor: pointer;
    text-decoration: underline;
  }

  .select {
    font-size: 16px;
    font-weight: bold;
    color: rgb(30, 170, 57);

  }

  ::v-deep .el-checkbox-group {
    max-height: 600px;
    overflow-y: auto;
  }
</style>